import consumer from "./consumer";

document.addEventListener("DOMContentLoaded", function(event) {
  const path_array = window.location.pathname.split('/');
  const idea_id = path_array[2];

  consumer.subscriptions.create(
    {
      channel: "BackgroundJobChannel",
      idea_id: idea_id
    },
    {
      connected() {
      },

      disconnected() {
      },

      received(data) {
        if (document.getElementById("background-job-broadcast").classList.value.includes("d-none")) {
          document.getElementById("background-job-broadcast").classList.remove("d-none");
          document.getElementById("background-job-broadcast").classList.add("d-block");
          document.getElementById("logs-loading").classList.remove("d-none");
          document.getElementById("logs-loading").classList.add("d-block");
        }

        appendLine(data["message"]);

        // auto scrolling
        let logsDiv = document.getElementById("background-job-broadcast");
        logsDiv.scrollTop = logsDiv.scrollHeight;

        if(data["status"] === "completed") {
          var codeGenerateButton = document.getElementById('push-to-github');
          var logsLoading = document.getElementById("logs-loading");
          codeGenerateButton.innerHTML = 'Generate Code';
          codeGenerateButton.disabled = false;
          logsLoading.classList.add("d-none");
          logsLoading.classList.remove("d-block");

          if(document.getElementById('github-view-code') === null) {
            codeGenerateButton.closest(".row").parentNode.insertBefore(
              htmlToElement(data["view_code_button_html"]),
              codeGenerateButton.closest(".row").nextElementSibling
            );
          }
        }
      }
    });
});

function appendLine(data) {
  const html = `<pre class="m-0">${data}</pre>`;
  const element = document.querySelector("#background-job-broadcast");
  element.insertAdjacentHTML("beforeend", html);
}

function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim();
  template.innerHTML = html;
  return template.content.firstChild;
}
